import React from "react"
import Layout from "../components/layout"
import Bio from "../components/bio"
import { Row, Col } from "react-bootstrap"
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaEnvelope,
  FaGithub,
} from "react-icons/fa"
import { graphql } from "gatsby"

const Contact = (props) => {
  console.log("props in contact::", props);
  const socialAccounts = props.data.allContentfulSocialAccount.nodes[0];
  const contact = props.data.allContentfulContact.nodes[0]
  return (
    <Layout>
      <Bio />
      <Row className="my-4">
        <Col>
          <h3>I'm Social!</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <a href={`mailto:${contact.email}`}>
            <FaEnvelope className="social-icon" />
          </a>
          <a
            href={socialAccounts.facebook}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook className="social-icon" />
          </a>
          <a
            href={socialAccounts.twitter}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter className="social-icon" />
          </a>
          <a
            href={socialAccounts.instagram}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram className="social-icon" />
          </a>
          <a
            href={socialAccounts.linkedin}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin className="social-icon" />
          </a>
          <a
            href="https://github.com/wsamipw"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGithub className="social-icon" />
          </a>
        </Col>
      </Row>
    </Layout>
  )
}

export default Contact
export const pageQuery = graphql`
  query {
    allContentfulSocialAccount {
      nodes {
        facebook
        twitter
        instagram
        linkedin
      }
    }
    allContentfulContact {
      nodes {
        address
        email
        mobileNumber
      }
    }
  }
`